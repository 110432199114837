<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Uji Petik Kabupaten</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div>
                            <div style="overflow-x:auto;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th style="width:5%">No</th>
                                          <th style="width:30%">Nama Kabupaten</th>
                                          <th style="width:15%">Penilaian Uji Petik</th>
                                          <th style="width:10%">Nilai Uji Petik</th>
                                          <th style="width:10%">Catatan</th>
                                          <th style="width:20%">Rekomendasi <br> <small style="color:red">(Hanya salah satu tim penilai input rekomendasi)</small></th>
                                          <th style="width:10%">BA</th>
                                      </tr>

                                  </thead>
                                  <tbody>
                                      <tr v-for="row in totskor" :key="row.id">
                                          <td></td>
                                          <td><div style="width:300px">{{row.nama_peserta}}</div></td>
                                          <td>
                                            <div style="width:150px" v-if="row.status ==='Unlock'">
                                              <b-button v-if="row.idpenilaian ==null" variant="success" v-b-modal.modal-input @click="getdatakabupaten(row.idpenilai,row.idpeserta)">Input Nilai</b-button>
                                              <b-button v-else variant="success" v-b-modal.modal-edit @click="getdatakabupatenedit(row.idpenilai,row.idpeserta)">Ubah Nilai</b-button>
                                            </div>
                                          </td>
                                          <td><b-button v-if="row.nilai_uji_petik != null" size="sm" style="background-color:#F9F9F9;" @click="nilai(row)" v-b-tooltip.hover title="Lihat data nilai"><i class="flaticon-eye" ></i></b-button></td>
                                          <td>
                                              <button data-toggle="modal" data-target=".catatan" @click="getcatatan(row.idpeserta)" style="margin:5px" class="btn btn-primary">Lihat</button>
                                          </td>
                                          <td>
                                              <button  @click="cekrekomendasi(row.idpenilai,row.idpeserta)" style="background-color: #030A8C; border-color: #030A8C; color:white" class="btn btn-primary">Input Rekomendasi</button><br>
                                          </td>
                                          <td>
                                            <button type="button" @click="cekba(row.idpeserta)" style="background-color: #004d00; border-color: #004d00" class="btn btn-success"><i class="fa fa-download" aria-hidden="true"/> Unduh BA</button>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            
            <!-- input nilai -->
            <b-modal
            v-model="show"
            id="modal-input"
            ref="modal"
            size="xl"
            modal-class="test-modal"
            title="Form Input Nilai"
            @show="resetModal"
            :no-close-on-backdrop="true"
            @ok="handleOk"
            >
            <form ref="form" @submit.stop.prevent="inputnilai">
            <div class="alert alert-primary d-flex align-items-center" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <div>
                <span style="padding-right:20px"> </span> Masukkan Penilaian Anda dengan rentang 1-100
                </div>
            </div>
                <div style="overflow-x:auto;">
            <table class="table table-bordered">
                <thead>
                <tr>
                    <th class="tg-1wig" colspan="2">Kriteria Penilaian</th>
                    <th class="tg-1wig" colspan="2">Indikator Penilaian</th>
                    <th>Nilai</th>
                    <th class="tg-0lax">Tolok Ukur</th>
                    <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Sangat </span><span style="font-weight:700">Bagus (85 sd 100)</span></th>
                    <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Bagus </span><span style="font-weight:700">(70 sd 84,9)</span></th>
                    <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Kurang</span><span style="font-weight:700"> Bagus (65 sd 69,9)</span><br></th>
                    <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Tidak </span><span style="font-weight:700">Bagus (dibawah 65)</span><br></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">1</span></td>
                    <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelaksanaan pelayanan perizinan berusaha di daerah</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ketersediaan data dan dokumen pendukung penilaian mandiri</span></td>
                    <td ><b-form-group invalid-feedback="Nilai is required" :state="p1aState"> <b-form-input type="number" step="any" style="width:100px" v-model="p1a" min="1" max="100" :state="p1aState" required ></b-form-input></b-form-group></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai ketersediaan data dan dokumen pendukung serta kualitas penyediaan dan organisasi dokumen dimaksud.</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data dan dokumen pendukung tersedia dengan sangat lengkap serta memiliki manajemen/ssitem pengarsipan yang sangat baik</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data dan dokumen pendukung tersedia dengan lengkap serta memiliki manajemen/ssitem pengarsipan yang baik</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data dan dokumen pendukung tersedia dengan kurang lengkap serta memiliki manajemen/ssitem pengarsipan yang kurang baik</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data dan dokumen pendukung tersedia dengan tidak lengkap karena manajemen/ssitem pengarsipan yang tidak baik</span></td>
                </tr>
                <tr>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kesesuaian pelaksanaan</span></td>
                    <td><b-form-group invalid-feedback="Nilai is required" :state="p1bState"> <b-form-input type="number" v-model="p1b" step="any" min="1" max="100" :state="p1bState" required ></b-form-input></b-form-group></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai kualitas kesesuain antara penilaian mandiri, paparan dengan pelaksanaan di lapangan</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat bukti yang sangat sahih semua kebijakan pelayanan telah dilakukan di lapangan</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat bukti yang sahih sebagian besar kebijakan pelayanan telah dilakukan di lapangan</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat bukti yang sahih sebagaian besar kebijakan pelayanan belum dilakukan di lapangan</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat bukti yang sahih semua kebijakan pelayanan belum dilakukan di lapanga</span></td>
                </tr>
                <tr>
                    <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">2</span></td>
                    <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelasanaan PPB</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelaksanaan pelayanan berusaha berdasarkan risiko</span></td>
                    <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required" :state="p2aState"> <b-form-input step="any" type="number" min="1" max="100" v-model="p2a" :state="p2aState" required ></b-form-input></b-form-group></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai kualitas implementasi pelayanan berusaha dengan sistem OSS</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelayanan perizinan berusaha telah sepenuhnya menggunakan sistem OSS yang ada, lengkap dengan kegiatan sosialisasi dan pendampingan yang sangat baik</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelayanan perizinan berusaha sudah menggunakan sistem OSS yang ada, namun kurang dalam sosialisasi dan atau pendampingannya</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelayanan perizinan berusaha belum sepenuhnya menggunakan sistem OSS yang ada meskipun ada kegiatan sosialisasi dan pendampingannya</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelayanan perizinan berusaha belum sepenuhnya menggunakan sistem OSS yang ada namun tidak disertai kegiatan sosialisasi dan pendampingan yang baik</span></td>
                </tr>
                <tr>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Penyederhaan peraturan dan pelaksanaan perizinan berusaha</span></td>
                    <td><b-form-group invalid-feedback="Nilai is required" :state="p2bState"> <b-form-input min="1" max="100" v-model="p2b" step="any" type="number" :state="p2bState" required ></b-form-input></b-form-group></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai kualitas aktivitas penyederhaan peraturan dan pelaksanaan perizinan berusaha</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua kegiatan penyederhaan peraturan pelaksanaan perizinan sudah selesai dilaksanakan dengan hasil-hasilnya</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua kegiatan penyederhaan peraturan pelaksanaan perizinan sudah selesai dilaksanakan namun dengan sebagian hasil-hasilnya</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ada sebagian kegiatan penyederhaan peraturan pelaksanaan perizinan yang belum selesai dilaksanakan termasuk hasil-hasilnya</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua kegiatan penyederhaan peraturan pelaksanaan perizinan sedang dalam proses dan belum ada hasilnya</span></td>
                </tr>
                </tbody>
                </table>
            </div>
            </form>
            <template #modal-footer>
                <div class="w-100">
                <!-- <p class="float-left">Modal Footer Content</p> -->
                <b-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    style="background-color: #030A8C; border-color: #030A8C; color:white"
                    @click="inputnilai(datakabupaten.idpenilai,datakabupaten.idpeserta)"
                >
                    Simpan
                </b-button>
                <b-button
                    variant="danger"
                    size="sm"
                    class="float-right"
                    style="margin-right: 10px;"
                    @click="show=false"
                >
                    Tutup
                </b-button>
                </div>
            </template>
            </b-modal>

            <!-- edit nilai -->
            <b-modal
            v-model="show1"
            id="modal-edit"
            ref="modal"
            size="xl"
            title="Form Ubah Nilai"
            @show="resetModal"
            :no-close-on-backdrop="true"
            modal-class="test-modal"
            @ok="handleOk"
            >
            <form ref="form">
            <div class="alert alert-primary d-flex align-items-center" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <div>
                <span style="padding-right:20px"> </span> Masukkan Penilaian Anda dengan rentang 1-100
                </div>
            </div>

            <div style="overflow-x:auto;">
                <table class="table table-bordered">
                <thead>
                <tr>
                    <th class="tg-1wig" colspan="2">Kriteria Penilaian</th>
                    <th class="tg-1wig" colspan="2">Indikator Penilaian</th>
                    <th>Nilai</th>
                    <th class="tg-0lax">Tolok Ukur</th>
                    <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Sangat </span><span style="font-weight:700">Bagus (85 sd 100)</span></th>
                    <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Bagus </span><span style="font-weight:700">(70 sd 84,9)</span></th>
                    <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Kurang</span><span style="font-weight:700"> Bagus (65 sd 69,9)</span><br></th>
                    <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Tidak </span><span style="font-weight:700">Bagus (dibawah 65)</span><br></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">1</span></td>
                    <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelaksanaan pelayanan perizinan berusaha di daerah</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ketersediaan data dan dokumen pendukung penilaian mandiri</span></td>
                    <td ><b-form-group invalid-feedback="Nilai is required" :state="p1aState"> <b-form-input type="number" style="width:100px" step="any" v-model="datakabupaten.p1a" min="1" max="100" :state="p1aState" required ></b-form-input></b-form-group></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai ketersediaan data dan dokumen pendukung serta kualitas penyediaan dan organisasi dokumen dimaksud.</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data dan dokumen pendukung tersedia dengan sangat lengkap serta memiliki manajemen/ssitem pengarsipan yang sangat baik</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data dan dokumen pendukung tersedia dengan lengkap serta memiliki manajemen/ssitem pengarsipan yang baik</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data dan dokumen pendukung tersedia dengan kurang lengkap serta memiliki manajemen/ssitem pengarsipan yang kurang baik</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data dan dokumen pendukung tersedia dengan tidak lengkap karena manajemen/ssitem pengarsipan yang tidak baik</span></td>
                </tr>
                <tr>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kesesuaian pelaksanaan</span></td>
                    <td><b-form-group invalid-feedback="Nilai is required" :state="p1bState"> <b-form-input type="number" style="width:100px" v-model="datakabupaten.p1b" step="any" min="1" max="100" :state="p1bState" required ></b-form-input></b-form-group></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai kualitas kesesuain antara penilaian mandiri, paparan dengan pelaksanaan di lapangan</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat bukti yang sangat sahih semua kebijakan pelayanan telah dilakukan di lapangan</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat bukti yang sahih sebagian besar kebijakan pelayanan telah dilakukan di lapangan</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat bukti yang sahih sebagaian besar kebijakan pelayanan belum dilakukan di lapangan</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat bukti yang sahih semua kebijakan pelayanan belum dilakukan di lapangan</span></td>
                </tr>
                <tr>
                    <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">2</span></td>
                    <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelaksanaan PPB</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelaksanaan pelayanan berusaha berdasarkan risiko</span></td>
                    <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required" :state="p2aState"> <b-form-input step="any" style="width:100px" type="number" min="1" max="100" v-model="datakabupaten.p2a" :state="p2aState" required ></b-form-input></b-form-group></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai kualitas implementasi pelayanan berusaha dengan sistem OSS</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelayanan perizinan berusaha telah sepenuhnya menggunakan sistem OSS yang ada, lengkap dengan kegiatan sosialisasi dan pendampingan yang sangat baik</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelayanan perizinan berusaha sudah menggunakan sistem OSS yang ada, namun kurang dalam sosialisasi dan atau pendampingannya</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelayanan perizinan berusaha belum sepenuhnya menggunakan sistem OSS yang ada meskipun ada kegiatan sosialisasi dan pendampingannya</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelayanan perizinan berusaha belum sepenuhnya menggunakan sistem OSS yang ada namun tidak disertai kegiatan sosialisasi dan pendampingan yang baik</span></td>
                </tr>
                <tr>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Penyederhanaan peraturan dan pelaksanaan perizinan berusaha</span></td>
                    <td><b-form-group invalid-feedback="Nilai is required" :state="p2bState"> <b-form-input min="1" max="100" style="width:100px" v-model="datakabupaten.p2b" step="any" type="number" :state="p2bState" required ></b-form-input></b-form-group></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai kualitas aktivitas penyederhanaan peraturan dan pelaksanaan perizinan berusaha</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua kegiatan penyederhaan peraturan pelaksanaan perizinan sudah selesai dilaksanakan dengan hasil-hasilnya</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua kegiatan penyederhaan peraturan pelaksanaan perizinan sudah selesai dilaksanakan namun dengan sebagian hasil-hasilnya</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ada sebagian kegiatan penyederhaan peraturan pelaksanaan perizinan yang belum selesai dilaksanakan termasuk hasil-hasilnya</span></td>
                    <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua kegiatan penyederhaan peraturan pelaksanaan perizinan sedang dalam proses dan belum ada hasilnya</span></td>
                </tr>
                </tbody>
                </table>
            </div>
            </form>
            <template #modal-footer>
                <div class="w-100">
                <!-- <p class="float-left">Modal Footer Content</p> -->
                <b-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    style="background-color: #030A8C; border-color: #030A8C; color:white"
                    @click="ubahnilai(datakabupaten.id)"
                >
                    Simpan
                </b-button>
                <b-button
                    variant="danger"
                    size="sm"
                    class="float-right"
                    style="margin-right: 10px;"
                    @click="show1=false"
                >
                    Tutup
                </b-button>
                </div>
            </template>
            </b-modal>

            <!-- lihat catatan -->
            <div class="modal fade catatan" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <div>
                                <div style="overflow-x:auto;">
                                    <table id="example2" class="table table-striped table-bordered" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Judul Kajian/Berita</th>
                                                <th>Tipe Kajian/Berita</th>
                                                <th>File</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="row in catatann" :key="row.id">
                                                <td></td>
                                                <td>{{row.judul}}</td>
                                                <td>
                                                    <div v-if="row.tipe === 'Negatif'" style="color:red">{{row.tipe}}</div>
                                                    <div v-else style="color:blue">{{row.tipe}}</div>
                                                </td>
                                                <td>
                                                    <div v-if="row.file != null">
                                                        <a :href="row.file" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <button data-toggle="modal" data-target="#modal-catatan" @click="getPerCatatan(row.id)" style="margin:5px" class="btn btn-secondary" v-b-tooltip.hover title="Lihat"><i class="fa fa-eye"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                        </div>
                </div>
            </div>

            <!-- modal detail catatan -->
            <div class="modal fade" id="modal-catatan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Lihat Catatan</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form ref="form" @submit.stop.prevent="tambahcatatan">
                

                                <div class="form-group row">
                                    <label class="col-4">Tipe</label>
                                    <div class="col-8">
                                        <b-form-select disabled v-model="getCatatan.tipe" :options="tipe"></b-form-select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Penghimpun Kajian/Berita</label>
                                    <div class="col-lg-8">
                                        <input disabled type="text" v-model="getCatatan.penghimpun" class="form-control"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Judul Kajian/Berita</label>
                                    <div class="col-lg-8">
                                        <input disabled type="text" v-model="getCatatan.judul" class="form-control"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Lembaga</label>
                                    <div class="col-lg-8">
                                        <input disabled type="text" v-model="getCatatan.lembaga" class="form-control"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Rentang Waktu</label>
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-xl-6">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <b-form-datepicker disabled  v-model="getCatatan.tanggal_awal" locale="in" class="mb-2"></b-form-datepicker>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                            <div class="col-xl-6">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <b-form-datepicker disabled  v-model="getCatatan.tanggal_akhir" locale="in" class="mb-2"></b-form-datepicker>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Ringkasan Isi Kajian/Berita</label>
                                    <div class="col-lg-8">
                                        <div v-html="getCatatan.deskripsi"></div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Tautan Kajian/Berita</label>
                                    <div class="col-lg-8">
                                        <input disabled type="text" v-model="getCatatan.sumber" class="form-control"/>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Kajian/Berita</label>
                                    <div class="col-lg-8">
                                        <!-- <b-form-file v-model="getCatatan.file" type="file" ref="file"></b-form-file> -->
                                        <div v-if="getCatatan.file !== null">
                                            <a :href="getCatatan.file" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                        </div>
                                    </div>
                                </div>
            </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        
                    </div>
                    </div>
                </div>
            </div>

            <!-- modal tambah rekomendasi -->
            <b-modal
                id="modal-rekomendasi"
                ref="modal"
                size="xl"
                modal-class="test-modal"
                title="Form Rekomendasi"
                 scrollable
                @show="resetModal"
                :no-close-on-backdrop="true"
                @ok="handleOk3"
                >
                <form ref="form3" @submit.stop.prevent="inputrekomendasi">
                    <div class="row">
                        <div class="col-md-12">
                        <h5>A. Pelayanan Perizinan Berusaha</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 1. Kelembagaan</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                >
                                  <input type="text" hidden v-model="resume_kelembagaan"/>   
                                <ckeditor :editor="editor" v-model="resume_kelembagaan"  :config="editorConfig"></ckeditor>
                                  <!-- <vue-editor v-model="resume_kelembagaan" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="rekomendasi_kelembagaan"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_kelembagaan" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 2. SDM </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="resume_sdm"  :config="editorConfig"></ckeditor>
                                
                                    <!-- <vue-editor v-model="resume_sdm" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="rekomendasi_sdm"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_sdm" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 3. Sarpras</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="resume_sarpras"  :config="editorConfig"></ckeditor>
                                
                                    <!-- <vue-editor v-model="resume_sarpras" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="rekomendasi_sarpras"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_sarpras" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b>4. Implementasi OSS</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="resume_implementasi_oss"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="resume_implementasi_oss" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="rekomendasi_implementasi_oss"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_implementasi_oss" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 5. Capaian target-target </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="resume_target_capaian"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="resume_target_capaian" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="rekomendasi_target_capaian"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_target_capaian" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-md-12">
                        <h5> B. Percepatan Pelaksanaan Berusaha </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b>  1. Peningkatan iklim berusaha </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="resume_peningkatan_iklim_berusaha"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="resume_peningkatan_iklim_berusaha" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="rekomendasi_peningkatan_iklim_berusaha"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_peningkatan_iklim_berusaha" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 2. Tim pelaksana PPB </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="resume_organisasi_pelaksana_ppb"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="resume_organisasi_pelaksana_ppb" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="rekomendasi_organisasi_pelaksana_ppb"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_organisasi_pelaksana_ppb" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 3. Dukungan pimpinan daerah </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="resume_dukungan_pimpinan_daerah"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="resume_dukungan_pimpinan_daerah" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="rekomendasi_dukungan_pimpinan_daerah"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_dukungan_pimpinan_daerah" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </form>
                <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="inputrekomendasi(datakabupaten.idpenilai,datakabupaten.idpeserta)"
                    >
                        Simpan
                    </b-button>
                    </div>
                </template>
                </b-modal>

            <!-- modal ubah rekomendasi -->
            <b-modal
                id="modal-editrekomendasi"
                ref="modal"
                size="xl"
                modal-class="test-modal"
                title="Ubah Rekomendasi"
                 scrollable
                @show="resetModal"
                :no-close-on-backdrop="true"
                @ok="handleOk3"
                >
                
                <form ref="form3" @submit.stop.prevent="inputrekomendasi">
                    <div class="row">
                        <div class="col-md-12">
                        <h5>A. Pelayanan Perizinan Berusaha</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 1. Kelembagaan</b>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                >
                                
                                <ckeditor :editor="editor" v-model="datarekomendasi.resume_kelembagaan"  :config="editorConfig"></ckeditor>

                                </b-form-group>
                            </div>
                            <!-- <div class="container">
                                <vue-editor :editor-toolbar="customToolbar"/>   
                            </div> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_kelembagaan"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_kelembagaan" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 2. SDM </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.resume_sdm"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.resume_sdm" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_sdm"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_sdm" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 3. Sarpras</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.resume_sarpras"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.resume_sarpras" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_sarpras"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_sarpras" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b>4. Implementasi OSS</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.resume_implementasi_oss"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.resume_implementasi_oss" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_implementasi_oss"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_implementasi_oss" :editor-toolbar="customToolbar"/>   -->
                                </b-form-group> 
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 5. Capaian target-target </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.resume_target_capaian"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.resume_target_capaian" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_target_capaian"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_target_capaian" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-md-12">
                        <h5> B. Percepatan Pelaksanaan Berusaha </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b>  1. Peningkatan iklim berusaha </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.resume_peningkatan_iklim_berusaha"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.resume_peningkatan_iklim_berusaha" :editor-toolbar="customToolbar" />    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_peningkatan_iklim_berusaha"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_peningkatan_iklim_berusaha" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 2. Tim pelaksana PPB </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.resume_organisasi_pelaksana_ppb"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.resume_organisasi_pelaksana_ppb" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_organisasi_pelaksana_ppb"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_organisasi_pelaksana_ppb" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 3. Dukungan pimpinan daerah </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.resume_dukungan_pimpinan_daerah"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.resume_dukungan_pimpinan_daerah" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_dukungan_pimpinan_daerah"  :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_dukungan_pimpinan_daerah" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </form>
                <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="ubahrekomendasi(datarekomendasi.id)"
                    >
                        Simpan
                    </b-button>
                    </div>
                </template>
                </b-modal>

            </div>
        </div>
    <!-- </div>
  </div> -->


  
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}


.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}
/* .test-modal .modal-dialog {
    max-width: 85%;
    
} */
</style>
<style lang="">
  
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import Swal from "sweetalert2";
import { LOGOUT } from "@/core/services/store/auth.module";
import { VueEditor } from "vue2-editor";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'


import Vue from "vue";
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading, {
  dark: false, // default false
  text: 'Loading', // default 'Loading'
  loading: false, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})

export default {
  components: { VueEditor },
  data() {
      return {
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        tahun: new Date().getFullYear(),
        user: JSON.parse(localStorage.getItem('user')),
        totskor:[],
        idruangan:'',
        daerah:'',
        ruanganpenilai:[],
        tipe: [
         { value: 'Negatif', text: 'Negatif' },
         { value: 'Positif', text: 'Positif' },
        ],
        noCatatan:'',
        timpenilai:[],
        p1aState: null,
        p1bState: null,
        p2aState: null,
        p2bState: null,
        p1a:'',
        p1b:'',
        p2a:'',
        p2b:'',
        datakabupaten:[],
        show:false,
        show1:false,
        catatann:[],
        getCatatan:{
            id:'',
            tipe:'',
            penghimpun:'',
            judul:'',
            deskripsi:'',
            sumber:''
        },
        tipe: [
         { value: 'Negatif', text: 'Negatif' },
         { value: 'Positif', text: 'Positif' },
        ],
        customToolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
        ],
        editor: ClassicEditor,
        editorConfig:{},
        // editorConfig:{
        //     toolbar: [
        //         [ 'Bold', 'Italic', 'Underline' ],
        //         ['NumberedList', 'BulletedList'],
        //         [ 'Styles'],
		// 	]
        // },
        datarekomendasi:[],
        resume_kelembagaanState:null,
        resume_kelembagaan:'',
        rekomendasi_kelembagaan:'',
        rekomendasi_kelembagaanState:null,
        resume_sdm:'',
        resume_sdmState:null,
        rekomendasi_sdm:'',
        rekomendasi_sdmState:null,
        resume_sarpras:'',
        resume_sarprasState:null,
        rekomendasi_sarpras:'',
        rekomendasi_sarprasState:null,
        resume_implementasi_oss:'',
        resume_implementasi_ossState:null,
        rekomendasi_implementasi_oss:'',
        rekomendasi_implementasi_ossState:null,
        resume_target_capaian:'',
        resume_target_capaianState:null,
        rekomendasi_target_capaian:'',
        rekomendasi_target_capaianState:null,
        resume_peningkatan_iklim_berusaha:'',
        resume_peningkatan_iklim_berusahaState:null,
        rekomendasi_peningkatan_iklim_berusaha:'',
        rekomendasi_peningkatan_iklim_berusahaState:null,
        resume_organisasi_pelaksana_ppb:'',
        resume_organisasi_pelaksana_ppbState:null,
        rekomendasi_organisasi_pelaksana_ppb:'',
        rekomendasi_organisasi_pelaksana_ppbState:null,
        resume_dukungan_pimpinan_daerah:'',
        resume_dukungan_pimpinan_daerahState:null,
        rekomendasi_dukungan_pimpinan_daerah:'',
        rekomendasi_dukungan_pimpinan_daerahState:null,
      }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Uji Petik Kabupaten" }]);
    this.getdata()
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    format_desimal(value){
         if (value) {
           return value.replace(".",",");
          }
    },

    // dapatkan data uji petik per user
    loaddata(){
      this.totskor = null;
      axios.get(this.url+"/peserta_by_penilai_uji_petik?idpenilai="+this.user.id+"&tipe=Kabupaten&tahun="+ this.tahun,{
            headers: {
                'xth': this.token
            }
            })
      .then(response => {
          var nominee = response.data.data;
          this.totskor = nominee;
          this.initDatatable();
      }).catch(error=>{
          if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
            Swal.fire({
                title: error.response.data,
                text: "",
                icon: 'error',
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store
                        .dispatch(LOGOUT)
                        .then(() => this.$router.push({ name: "login" }));
                    }
                })
                } else {
                console.log(error)
                
                }
          return error
        });
    },

    // dapatkan catatan
    getcatatan(idptsp){
        $('#example2').DataTable().destroy();
        this.catatann = null,
        axios.get(this.url+"/catatan_ta_kabupaten?filter=idptsp,=,"+idptsp+";tahun,=,"+this.tahun,{
            headers: {
                'xth': this.token
            }
            })
            .then(response => {
                var catatannomine = response.data.data;
                this.catatann = catatannomine;
                this.initDatatable2()
            }).catch(error=>{
                return error
        });
    },

    // dapatkan detail catatan
    getPerCatatan(id){
        axios.get(this.url+"/catatan_ta_kabupaten?filter=id,=,"+id,{
            headers: {
                'xth': this.token
            }
            }).then(response => {
            this.getCatatan.id = id;
                this.getCatatan.tipe = response.data.data[0].tipe;
                this.getCatatan.penghimpun = response.data.data[0].penghimpun;
                this.getCatatan.judul = response.data.data[0].judul;
                this.getCatatan.deskripsi = response.data.data[0].deskripsi;
                this.getCatatan.sumber = response.data.data[0].sumber;
                this.getCatatan.tanggal_awal = response.data.data[0].tanggal_awal;
                this.getCatatan.tanggal_akhir = response.data.data[0].tanggal_akhir;
                this.getCatatan.lembaga = response.data.data[0].lembaga;
                this.getCatatan.idptsp = response.data.data[0].idptsp;
                this.getCatatan.file = response.data.data[0].file;
        });
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    initDatatable2() {
        setTimeout(() => {
        var t =  $('#example2').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },

    // dapatkan data ptsp
    getdatakabupaten(penilai, peserta){
        this.datakabupaten.idpenilai = penilai
        this.datakabupaten.idpeserta = peserta
    },

    // dapatkan nilai ptsp
    getdatakabupatenedit(id_penilai,id_peserta){
        axios.get(this.url+"/tabel_nilai_peserta_uji_petik?filter=idpenilai,=,"+id_penilai+";idpeserta,=,"+id_peserta+';tahun,=,'+this.tahun, {
            headers: {
                'xth': this.token
            }
            })
        .then(response => {
            this.datakabupaten = response.data.data[0];
        }).catch(error=>{
            return error
            });
    },

    // persetujuan
    persetujuanba(id) {
        axios.put(this.url+`/uji_petik_kabupaten/${id}`,
            {
                persetujuan:this.datakabupaten.persetujuan,
            }, {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-edit')
        })
    },

    // ubah nilai
    ubahnilai(id) {
        axios.put(this.url+`/tabel_nilai_peserta_uji_petik/${id}`,
            {
                p1a:this.datakabupaten.p1a,
                p1b:this.datakabupaten.p1b,
                p2a:this.datakabupaten.p2a,
                p2b:this.datakabupaten.p2b,
            }
            ,{
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Diedit', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Diedit', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-edit')
        })
    },
    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.p1aState = valid
        this.p1bState = valid
        this.p2aState = valid
        this.p2bState = valid
        return valid
      },
    resetModal() {
        this.p1a=''
        this.p1b=''
        this.p2a=''
        this.p2b=''
        this.p1aState = null
        this.p1bState = null
        this.p2aState = null
        this.p2bState = null

      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.inputnilai()
      },

    // input nilai
    inputnilai(penilai,peserta) {
        const p1a = this.p1a;
        const p1b = this.p1b;
        const p2a = this.p2a;
        const p2b = this.p2b;
        const tahun = this.tahun;
        const idpenilai = penilai;
        const idpeserta = peserta;
        if (!this.checkFormValidity()) {
          return
        }
        // console.log(id_peserta)
        
        axios
            .post(this.url+"/tabel_nilai_peserta_uji_petik",
            {
                p1a,
                p1b,
                p2a,
                p2b,
                tahun,
                idpenilai,
                idpeserta,
            },
            {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Disimpan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.resetModal()
                this.getdata()
                
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-input')
        })
    },

    cekba(idpeserta){
        var api = ''
            api = this.url+'/tabel_nomine_kabupaten?filter=tahun,=,'+this.tahun+';idptsp,=,'+idpeserta
       axios.get(api, {
                  headers: {
                      'xth': this.token
                  }
              })
            .then(response => {
                var data = []
                data =  response.data.data;
                this.downloadba(data[0].file_ba_uji_petik)
            }).catch(error=>{
                console.log(error)
                return error
                });
        
    },

    downloadba(ba){
        if (ba == null) {
            Swal.fire({
            title: "",
            text: "File BA Belum ada",
            icon: "info",
            confirmButtonClass: "btn btn-secondary"
            });
            this.getdata()
        }else{
            window.open(ba, '_blank')
        }
    },

    checkFormValidity2() {
        const valid = this.$refs.form3.checkValidity()
        this.resume_kelembagaanState = valid
        this.rekomendasi_kelembagaanState=valid
        this.resume_sdmState=valid
        this.rekomendasi_sdmState=valid
        this.resume_sarprasState=valid
        this.rekomendasi_sarprasState=valid
        this.resume_implementasi_ossState=valid
        this.rekomendasi_implementasi_ossState=valid
        this.resume_target_capaianState=valid
        this.rekomendasi_target_capaianState=valid
        this.resume_peningkatan_iklim_berusahaState=valid
        this.rekomendasi_peningkatan_iklim_berusahaState=valid
        this.resume_organisasi_pelaksana_ppbState=valid
        this.rekomendasi_organisasi_pelaksana_ppbState=valid
        this.resume_dukungan_pimpinan_daerahState=valid
        this.rekomendasi_dukungan_pimpinan_daerahState=valid
        return valid
      },
    
     resetModalRekomendasi() {
        this.resume_kelembagaanState=null
        this.resume_kelembagaan=""
        this.rekomendasi_kelembagaan=""
        this.rekomendasi_kelembagaanState=null
        this.resume_sdm=""
        this.resume_sdmState=null
        this.rekomendasi_sdm=null
        this.rekomendasi_sdmState=null
        this.resume_sarpras=null
        this.resume_sarprasState=null
        this.rekomendasi_sarpras=null
        this.rekomendasi_sarprasState=null
        this.resume_implementasi_oss=null
        this.resume_implementasi_ossState=null
        this.rekomendasi_implementasi_oss=null
        this.rekomendasi_implementasi_ossState=null
        this.resume_target_capaian=null
        this.resume_target_capaianState=null
        this.rekomendasi_target_capaian=null
        this.rekomendasi_target_capaianState=null
        this.resume_peningkatan_iklim_berusaha=null
        this.resume_peningkatan_iklim_berusahaState=null
        this.rekomendasi_peningkatan_iklim_berusaha=null
        this.rekomendasi_peningkatan_iklim_berusahaState=null
        this.resume_organisasi_pelaksana_ppb=null
        this.resume_organisasi_pelaksana_ppbState=null
        this.rekomendasi_organisasi_pelaksana_ppb=null
        this.rekomendasi_organisasi_pelaksana_ppbState=null
        this.resume_dukungan_pimpinan_daerah=null
        this.resume_dukungan_pimpinan_daerahState=null
        this.rekomendasi_dukungan_pimpinan_daerah=null
        this.rekomendasi_dukungan_pimpinan_daerahState=null

      },

    handleOk3(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.inputrekomendasi()
      },
    
    // input nilai
    inputrekomendasi(penilai, peserta) {
        this.$loading(true);
        const resume_kelembagaan = this.resume_kelembagaan;
        const rekomendasi_kelembagaan = this.rekomendasi_kelembagaan;
        const resume_sdm = this.resume_sdm;
        const rekomendasi_sdm = this.rekomendasi_sdm;
        const resume_sarpras = this.resume_sarpras;
        const rekomendasi_sarpras = this.rekomendasi_sarpras;
        const resume_implementasi_oss = this.resume_implementasi_oss;
        const rekomendasi_implementasi_oss = this.rekomendasi_implementasi_oss;
        const resume_target_capaian = this.resume_target_capaian;
        const rekomendasi_target_capaian = this.rekomendasi_target_capaian;
        const resume_peningkatan_iklim_berusaha = this.resume_peningkatan_iklim_berusaha;
        const rekomendasi_peningkatan_iklim_berusaha = this.rekomendasi_peningkatan_iklim_berusaha;
        const resume_organisasi_pelaksana_ppb = this.resume_organisasi_pelaksana_ppb;
        const rekomendasi_organisasi_pelaksana_ppb = this.rekomendasi_organisasi_pelaksana_ppb;
        const resume_dukungan_pimpinan_daerah = this.resume_dukungan_pimpinan_daerah;
        const rekomendasi_dukungan_pimpinan_daerah = this.rekomendasi_dukungan_pimpinan_daerah;
        const tahun = this.tahun;
        const id_penilai = penilai;
        const id_peserta = peserta;
        // console.log(id_peserta)
        
        axios
            .post(this.url+"/rekomendasi_tim_penilai",
            {
                resume_kelembagaan,
                rekomendasi_kelembagaan,
                resume_sdm,
                rekomendasi_sdm,
                resume_sarpras,
                rekomendasi_sarpras,
                resume_implementasi_oss,
                rekomendasi_implementasi_oss,
                resume_target_capaian,
                rekomendasi_target_capaian,
                resume_peningkatan_iklim_berusaha,
                rekomendasi_peningkatan_iklim_berusaha,
                resume_organisasi_pelaksana_ppb,
                rekomendasi_organisasi_pelaksana_ppb,
                resume_dukungan_pimpinan_daerah,
                rekomendasi_dukungan_pimpinan_daerah,
                tahun,
                id_penilai,
                id_peserta,
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$loading(false);
                this.$bvToast.toast('Berhasil Disimpan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                // this.resetModalRekomendasi()
                // this.datarekomendasi = []
                
            return res;
            })
            .catch((err) => {
                this.$loading(false);
            console.log(err)
            this.$bvToast.toast('Gagal Disimpan', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-rekomendasi')
        })
    },

    // lihat nilai
    nilai(data){
        Swal.fire({
            title: 'Nilai',
            icon: 'info',
            html:
                data.nama_peserta + '<br>' +
              '<strong style="font-size:30px">'+ this.format_desimal(data.nilai_uji_petik) + '</strong>',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            showConfirmButton: false,
        })
    },

    ubahrekomendasi(id){
        this.$loading(true);
        axios.put(this.url+`/rekomendasi_tim_penilai/${id}`,
            {
                resume_kelembagaan : this.datarekomendasi.resume_kelembagaan,
                rekomendasi_kelembagaan : this.datarekomendasi.rekomendasi_kelembagaan,
                resume_sdm : this.datarekomendasi.resume_sdm,
                rekomendasi_sdm : this.datarekomendasi.rekomendasi_sdm,
                resume_sarpras : this.datarekomendasi.resume_sarpras,
                rekomendasi_sarpras : this.datarekomendasi.rekomendasi_sarpras,
                resume_implementasi_oss : this.datarekomendasi.resume_implementasi_oss,
                rekomendasi_implementasi_oss : this.datarekomendasi.rekomendasi_implementasi_oss,
                resume_target_capaian : this.datarekomendasi.resume_target_capaian,
                rekomendasi_target_capaian : this.datarekomendasi.rekomendasi_target_capaian,
                resume_peningkatan_iklim_berusaha : this.datarekomendasi.resume_peningkatan_iklim_berusaha,
                rekomendasi_peningkatan_iklim_berusaha : this.datarekomendasi.rekomendasi_peningkatan_iklim_berusaha,
                resume_organisasi_pelaksana_ppb : this.datarekomendasi.resume_organisasi_pelaksana_ppb,
                rekomendasi_organisasi_pelaksana_ppb : this.datarekomendasi.rekomendasi_organisasi_pelaksana_ppb,
                resume_dukungan_pimpinan_daerah : this.datarekomendasi.resume_dukungan_pimpinan_daerah,
                rekomendasi_dukungan_pimpinan_daerah : this.datarekomendasi.rekomendasi_dukungan_pimpinan_daerah,
                tahun : this.datarekomendasi.tahun,
                id_penilai : this.datakabupaten.idpenilai,
                id_peserta : this.datakabupaten.idpeserta,
            }
            , {
            headers: {
                'xth': this.token
            }
            })
            .then((res) => {
                this.$loading(false);
                this.$bvToast.toast('Berhasil Diedit', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
                this.$loading(false);
            console.log(err)
            this.$bvToast.toast('Gagal Diedit', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-editrekomendasi')
        })
    },

    // cek rekomendasi
    cekrekomendasi(idpenilai,idptsp){
        axios.get(this.url+"/rekomendasi_tim_penilai?filter=id_peserta,=,"+idptsp+";tahun,=,"+this.tahun, {
            headers: {
                'xth': this.token
            }
            })
        .then(response => {
            var data = response.data.data;
            // this.datarekomendasi = response.data.data[0];
            if (data.length > 0) {
                this.getdatakabupaten(idpenilai,idptsp)
                // this.datarekomendasi.resume_kelembagaan = data[0].resume_kelembagaan
                this.datarekomendasi = response.data.data[0];
                this.$bvModal.show('modal-editrekomendasi')
            } else {
                console.log(idpenilai, idptsp)
                console.log(this.resume_kelembagaan)
                this.getdatakabupaten(idpenilai,idptsp)
                this.$bvModal.show('modal-rekomendasi')
            }
        }).catch(error=>{
            return error
            });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
};
</script>
